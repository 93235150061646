
import { defineComponent } from "@vue/runtime-core";
import AppPage from "@/components/AppPage.vue";
import Button from "@/components/Button.vue";
import DataTable from "@/components/DataTable.vue";
import { Option, Pagination, Sorting } from "@/use/types";
import TextInput from "@/components/TextInput.vue";
import SubsetInput from "@/components/SubsetInput.vue";
import Category from "@/components/Category.vue";
import Daterange from "@/components/Daterange.vue";
import Tooltip from "@/components/Tooltip.vue";
import Unpublish from "./Unpublish.vue";
import Editor from "./Editor.vue";
import Publish from "./Publish.vue";

import moment, { Moment } from "moment-timezone";
import axios from "axios";

interface IDataModel {
  loading: boolean;
  filter: IQuery;
  rows: any[];
  domains: any[];
  totalRows: number;
  columns: Column[];
  options: {
    categories: Option[];
    geos: Option[];
  };
  definition: any;
  actionsVisibleIndex: number | null;
  isUnpublishPopupShowed: boolean;
  unpublishNewsId: number | null;
  newsToEdit: null;
  isPublishPopupShowed: boolean;
  publishNewsId: number | null;
}

interface IQuery {
  period: Moment[];
  search: string;
  pagination: null | Pagination;
  sorting: Sorting;
  category_ids: number[];
  geo_ids: number[];
  not_published: boolean;
}

interface Column {
  id: number | string;
  name: string;
  selected: boolean;
  freezed?: boolean;
}

export function getDefaultQuery(): IQuery {
  return {
    period: [],
    search: "",
    sorting: { column: "updated_at", direction: "desc" },
    pagination: { limit: 10, offset: 0 },
    category_ids: [],
    geo_ids: [],
    not_published: false,
  };
}

const dataColumns = [
  {
    id: "updated_at",
    name: "Дата",
    selected: true,
    sortable: true,
    freezed: true,
  },
  {
    id: "id",
    name: "ID",
    selected: true,
    sortable: true,
    freezed: true,
  },
  {
    id: "image_filename",
    name: "Изображение",
    selected: true,
    sortable: false,
    htmlClass: "news__table--image",
  },
  {
    id: "title",
    name: "Заголовок",
    selected: true,
    sortable: true,
    htmlClass: "news__table--h",
    type: "text",
    searchable: true,
  },
  {
    id: "category_name",
    name: "Категория",
    selected: true,
    sortable: true,
    htmlClass: "news__table--mark",
    type: "text",
  },
  {
    id: "geo",
    name: "Гео",
    selected: true,
    sortable: false,
    htmlClass: "news__table--geo",
  },
  {
    id: "priority",
    name: "Приоритет",
    selected: true,
    sortable: true,
    htmlClass: "news__table--priority",
  },
  {
    id: "buttons",
    name: "",
    selected: true,
    freezed: true,
    sortable: false,
    htmlClass: "news__table--action",
  },
];

export default defineComponent({
  components: {
    AppPage,
    Button,
    DataTable,
    TextInput,
    SubsetInput,
    Category,
    Daterange,
    Tooltip,
    Unpublish,
    Editor,
    Publish,
  },
  data(): IDataModel {
    return {
      loading: false,
      filter: getDefaultQuery(),
      rows: [],
      domains: [],
      totalRows: 0,
      columns: dataColumns,
      options: {
        categories: [],
        geos: [],
      },
      definition: {
        shortcut: null,
        timezone: "Europe/Kiev",
      },
      actionsVisibleIndex: null,
      isUnpublishPopupShowed: false,
      unpublishNewsId: null,
      newsToEdit: null,
      isPublishPopupShowed: false,
      publishNewsId: null,
    };
  },
  async created() {
    this.load();
    this.getCategories();
    this.getGeos();
    await this.getPeriodLimits();
  },
  mounted: function () {
    this.$watch("filter.not_published", this.load);
    this.$watch("filter.pagination", this.load);
    this.$watch("filter.sorting", this.load);
  },
  methods: {
    async filterOptions(collection: string, query = "", field?: string) {
      const { data } = await axios.get(
        "/api/" + (field || collection) + "/lookup",
        {
          params: { q: JSON.stringify({ query }) },
        }
      );
      const key: "geos" | "categories" = collection as any;
      this.options[key] = data;
    },
    async getPeriodLimits() {
      const { data } = await axios("/api/get-period-limits/news");
      this.filter.period = [
        data.min || moment().endOf("day"),
        moment().endOf("day"),
      ];
    },
    setActionsVisible(id: number | null) {
      if (this.actionsVisibleIndex === id) {
        this.actionsVisibleIndex = null;
      } else {
        this.actionsVisibleIndex = id;
      }
    },
    unpublishNews(id: number) {
      this.isUnpublishPopupShowed = true;
      this.unpublishNewsId = id;
      this.actionsVisibleIndex = null;
    },
    publishNews(id: number) {
      this.isPublishPopupShowed = true;
      this.publishNewsId = id;
      this.actionsVisibleIndex = null;
    },
    async closePopup() {
      await this.getPeriodLimits();

      if (this.isUnpublishPopupShowed) {
        this.isUnpublishPopupShowed = false;
        this.unpublishNewsId = null;
      }

      if (this.isPublishPopupShowed) {
        this.isPublishPopupShowed = false;
        this.publishNewsId = null;
      }

      if (this.newsToEdit) {
        this.newsToEdit = null;
        document.querySelector("body")?.classList.remove("editor-opened");
      }

      this.load();
    },
    getGeoIdByIsoCode(iso_code: string): number {
      return this.options.geos.find((x: any) => x.iso_code === iso_code)?.id as number;
    },
    openNews(id: number, iso_code: string) {
      const geo_id = this.getGeoIdByIsoCode(iso_code);
      const domain = this.domains.find((x: any) => x.geo_id === geo_id)?.name;
      window.open(`https://${domain}/${id}`, "_blank");
      this.actionsVisibleIndex = null;
    },
    formatDate(d: string): string {
      return moment(d).format("DD.MM.YYYY");
    },
    async getCategories() {
      const { data } = await axios.get("/api/categories/lookup");
      this.options.categories = data;
    },
    async getGeos() {
      const { data } = await axios.get("/api/geos/lookup-for-news");
      this.options.geos = data;
    },
    updateCategoriesAndLoad(e: any) {
      this.filter.category_ids = e;
    },
    updateGeosAndLoad(e: any) {
      this.filter.geo_ids = e;
    },
    async clear() {
      this.filter = getDefaultQuery();
      await this.getPeriodLimits();
      this.load();
    },
    async setPeriod(value: any) {
      if (!value.length) {
        await this.getPeriodLimits();
        return;
      }
      this.filter.period = value;
    },
    async load() {
      this.loading = true;
      this.rows = [];
      this.totalRows = 0;

      try {
        const [{ data: news }, { data: domains }] = await Promise.all([
          axios.get("/api/news", {
            params: { q: JSON.stringify(this.filter) },
          }),
          axios.get("/api/domains"),
        ]);
        this.rows = news.rows;
        this.totalRows = news.total;
        this.domains = domains;
      } catch (e) {
        this.$router.push(`/error/${(e.response && e.response.status) || 500}`);
      }

      this.loading = false;
    },
    toNotPublished() {
      this.filter.not_published = true;
    },
    toActive() {
      this.filter.not_published = false;
    },
    download() {
      window.open(
        `/api/news/download?q=${encodeURIComponent(
          JSON.stringify(this.filter)
        )}`,
        "_blank"
      );
    },
    openEditor(row: any) {
      document.querySelector("body")?.classList.add("editor-opened");

      this.newsToEdit = (row && {
        ...row,
        priority: row.priority.toString(),
      }) || {
        title: "",
        title_secondary: "",
        category_id: null,
        source_link: "",
        author: "",
        type_id: null,
        priority: "1",
        is_published: false,
        geo_id: null,
        image_filename: null,
        square_img_crop: null,
        rectangle1_img_crop: null,
        rectangle2_img_crop: null,
        square_img_path: null,
        rectangle1_img_path: null,
        rectangle2_img_path: null,
      };

      if (this.actionsVisibleIndex !== null) this.actionsVisibleIndex = null;
    },
  },
});
