
import { defineComponent } from "@vue/runtime-core";
import Popup from "@/components/Popup.vue";
import TextInput from "@/components/TextInput.vue";
import Dropdown from "@/components/Dropdown.vue";
import Button from "@/components/Button.vue";
import TipTap from "@/components/TipTap.vue";

import axios from "axios";
import moment from "moment-timezone";
import VueCropper from "vue-cropperjs";
import { marked } from "marked";
import "cropperjs/dist/cropper.css";
import * as _ from "lodash";

interface IDataModel {
  options: {
    categories: Array<Option>;
    geos: Array<Option>;
    newsTypes: Array<Option>;
  };
  errors: { [k: string]: string | null };
  image: any;
  images: any;
  isCropperExist: boolean;
  tab: number | null;
  isMarkdown: boolean;
  isSections: boolean;
  newsDetails: Array<newsDetails>;
  isPreviewFieldShowed: boolean;
  loading: boolean;
  tiptapContent: string;
  activeSection: any;
  toEditSectionIndex: number | null;
}

interface newsDetails {
  text: string;
  text_type: string;
}

interface Option {
  id: number;
  name: string;
}

export default defineComponent({
  components: {
    Popup,
    TextInput,
    Dropdown,
    Button,
    VueCropper,
    TipTap,
  },
  props: {
    news: Object,
  },
  data(): IDataModel {
    return {
      loading: false,
      options: {
        categories: [],
        newsTypes: [],
        geos: [],
      },
      image: null,
      errors: {} as { [key: string]: string | null },
      isCropperExist: false,
      images: {
        square: null,
        rectangle1: null,
        rectangle2: null,
      },
      tab: 0,
      isMarkdown: true,
      isSections: false,
      newsDetails: [],
      isPreviewFieldShowed: false,
      tiptapContent: "",
      activeSection: null,
      toEditSectionIndex: null,
    };
  },
  emits: ["close", "update:news", "saved"],
  async created() {
    this.searchData("", "geos");
    this.searchData("", "categories");
    this.searchData("", "newsTypes");
    this.getNewsDetails();
  },
  watch: {
    tiptapContent(newValue) {
      if (this.isMarkdown) {
        this.onMarkdownChange(newValue);
      }

      if (this.isSections) {
        this.onSectionContentChange(
          this.newsDetails[this.toEditSectionIndex || 0],
          newValue
        );
      }
    },
    newsDetails(newValue: any) {
      if (newValue.length) {
        if (!newValue[0].text) {
          this.$emit("update:news", {
            ...this.news,
            is_published: false,
          });
        }
      } else {
        this.$emit("update:news", {
          ...this.news,
          is_published: false,
        });
      }
    },
  },
  computed: {
    updatedAtDate() {
      return moment(new Date()).format("DD/MM/YYYY");
    },
    markdownToHtml() {
      return marked(
        this.newsDetails.length ? this.newsDetails[0].text : "<span></span>"
      );
    },
  },
  methods: {
    saveEditing() {
      this.toEditSectionIndex = null;
    },
    setActiveSection(i: number) {
      this.activeSection = i;
    },
    onEditSection(value: any, i: number) {
      this.tiptapContent = value.text;
      this.toEditSectionIndex = i;
    },
    toSections() {
      this.isMarkdown = false;
      this.isSections = true;
    },
    toMarkdown() {
      this.toEditSectionIndex = null;
      this.isMarkdown = true;
      this.isSections = false;
    },
    cancel() {
      this.tab = 0;
      this.isCropperExist = false;
      this.image = null;
      if (!this.news?.id) {
        this.$emit("update:news", {
          ...this.news,
          image_filename: null,
        });
      }
    },
    async setImage(e: any) {
      const file = e.target.files[0];
      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.image = event.target?.result;
          this.isCropperExist = true;
          this.tab = 0;
          this.$emit("update:news", {
            ...this.news,
            image_filename: this.imageToBlob(this.image),
          });
          // rebuild cropperjs with the updated source
          // this.$refs.cropper.replace(event.target.result);
        };
        this.errors.image_filename = null;
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    next() {
      const cropper = this.$refs.cropper as any;

      if (this.tab === 0) {
        this.images.square = cropper.getCroppedCanvas().toDataURL();
        cropper.setAspectRatio(1.75);
      }

      if (this.tab === 1) {
        this.images.rectangle1 = cropper.getCroppedCanvas().toDataURL();
        cropper.setAspectRatio(0.82);
      }

      if (this.tab === 2) {
        this.cropImage();
      }

      if (this.tab !== 2) {
        this.tab = (this.tab || 0) + 1;
      }
    },
    async imageToBlob(image: any) {
      return image && (await (await fetch(image)).blob());
    },
    async cropImage() {
      const cropper = this.$refs.cropper as any;

      if (this.tab === 2) {
        this.images.rectangle2 = cropper.getCroppedCanvas().toDataURL();
      }

      this.images.square =
        this.images.square && (await this.imageToBlob(this.images.square));
      this.images.rectangle1 =
        this.images.rectangle1 &&
        (await this.imageToBlob(this.images.rectangle1));
      this.images.rectangle2 =
        this.images.rectangle2 &&
        (await this.imageToBlob(this.images.rectangle2));

      this.isCropperExist = false;
    },
    formatDate(date: string) {
      return moment(date).format("DD/MM/YYYY");
    },
    close() {
      this.$emit("close");
    },
    emitPatch(patch: any) {
      this.$emit("update:news", {
        ...this.news,
        ...patch,
      });

      for (const k of Object.keys(patch)) {
        this.errors[k] = null;
      }
    },
    async searchData(query: string, collection: string) {
      const { data } = await axios.get(
        `/api/${
          collection !== "newsTypes"
            ? collection + "/lookup"
            : "news/" + collection
        }`,
        {
          params: {
            q: JSON.stringify({
              query,
            }),
          },
        }
      );

      const key: "geos" | "categories" | "newsTypes" = collection as any;
      this.options[key] = data;
    },
    onAddSection() {
      const sections = _.cloneDeep(this.newsDetails);
      sections.push({ text: "", text_type: "raw" });
      this.newsDetails = sections;
    },
    onRemoveSection(index: number) {
      const sections = _.cloneDeep(this.newsDetails);
      sections.splice(index, 1);
      this.newsDetails = sections;
    },
    onMarkdownChange(value: string) {
      const sections = _.cloneDeep(this.newsDetails);
      if (!sections.length)
        sections.push({ text: value, text_type: "markdown" });
      else sections[0].text = value;
      this.newsDetails = sections;
      this.errors.text = null;
    },
    onSectionContentChange(section: any, value: string) {
      const index = this.newsDetails.indexOf(section);
      const sections = _.cloneDeep(this.newsDetails);
      sections[index].text = value;
      if (sections[index].text_type !== "raw") {
        sections[index].text_type = "raw";
      }
      this.newsDetails = sections;
      this.errors.text = null;
    },
    async getNewsDetails() {
      if (this.news?.id) {
        const { data } = await axios.get("/api/news-details/" + this.news?.id);
        this.newsDetails = data;
      }
    },
    async saveNews() {
      this.loading = true;
      let formData = new FormData();

      for (const name in this.images) {
        formData.append(name, this.images[name]);
      }

      for (const name in this.news) {
        formData.append(name, this.news[name]);
      }

      formData.append("image_filename", await this.imageToBlob(this.image));

      try {
        const { data: news } = await axios.post("/api/news", formData);
        await axios.post("/api/news-details/" + news.id, this.newsDetails);

        this.$emit("saved");
        this.$emit("close");
      } catch (e) {
        const status = e.response && e.response.status;
        if (status === 400) {
          this.errors = e.response.data;
          if (!this.newsDetails.length) {
            this.errors = { ...this.errors, text: "Введите текст" };
          }
        } else {
          this.$router.push(`/error/${status || 500}`);
        }
      }

      this.loading = false;
    },
  },
});
